import React from "react";
import { graphql, Link } from "gatsby";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import theme from "../gatsby-theme-material-ui-top-layout/theme";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import {
  Container,
  Grid,
  Typography,
  Button,
  Stack,
  Card,
  Avatar,
} from "@mui/material";

import VerifiedImg from "../components/icon/verified.png";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    audits: allSanityAudit {
      edges {
        node {
          id
          name
          hidden
          logo {
            _key
            _type
            _rawAsset
            _rawHotspot
            _rawCrop
          }
          slug {
            _key
            _type
            current
          }
          website
          commissioned
          status
          description {
            _key
            _type
            style
            list
            _rawChildren
          }
          network
          contracts {
            issues {
              _key
              _type
              description
              severity
              status
            }
            address
            _type
            _key
          }
          auditReport {
            _key
            _type
            _rawAsset
          }
          category
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  let issuesCount = 0;
  let auditsCompleted = 0;

  const site = (data || {}).site;
  const auditNodes = (data || {}).audits
    ? mapEdgesToNodes(data.audits).filter(filterOutDocsWithoutSlugs)
    : //.filter(filterOutDocsPublishedInTheFuture)
    [];

  auditNodes
    .filter((aN) => aN.status.toLowerCase() === "completed")
    .map((a) => {
      auditsCompleted++;
      a.contracts.map((c) => c.issues.map((issue) => issuesCount++));
    });

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />

      <Container
        maxWidth="md"
        sx={{
          height: "100%",
          minHeight: "500px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Grid textAlign="center">
          <Typography color="white" variant="h4" fontWeight="bold">
            Our mission is to secure the decentralized internet
          </Typography>
          <Typography
            mt={3}
            variant="subtitle1"
            color={theme.palette.primary.main}
          >
            Veracity is a leading smart contract auditing company with an
            essential focus on decentralized finance protocols. We work mainly
            with EVM-compatible protocols to help increase the security of their
            contracts.
          </Typography>
          <Grid justifyContent="center" container spacing={3}>
            <Grid item>
              <Button
                component={Link}
                to="/contact"
                variant="contained"
                sx={{ mt: 3 }}
              >
                Request quote
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                to="/audits"
                sx={{
                  mt: 3,
                  fontWeight: "bold",
                  border: "none",
                  color: "white",
                }}
              >
                See our audits
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth={false}
        sx={{
          background: "white",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          py: 10,
        }}
      >
        <Grid my={5} container maxWidth="md" justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h4"
              fontWeight="bold"
              color={theme.palette.background.default}
            >
              High level security audits for Web3 dApps
            </Typography>
            <Typography mt={3} variant="subtitle1">
              Our team of independent top auditors and white hat hackers are
              specialists in decentralized finance protocols and are ready to
              assist you in evaluating your project for safety, functionality,
              protection, and utility. We provide user-centric audits as well as
              contract functionality assessments. Please contact us to get a
              quote for your unique protocol.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} textAlign="center">
            <img
              style={{ width: "100%", maxWidth: 200, padding: 16 }}
              src={VerifiedImg}
              alt="Our Audits"
            />
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color={theme.palette.background.default}
                >
                  {auditsCompleted}
                </Typography>
                <Typography variant="subtitle1" color="secondary">
                  {issuesCount !== 1 ? "Audits completed" : "Audit completed"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color={theme.palette.background.default}
                >
                  {issuesCount}
                </Typography>
                <Typography variant="subtitle1" color="secondary">
                  {issuesCount !== 1 ? "Issues found" : "Issue found"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {auditsCompleted > 0 && (
        <Container
          maxWidth={false}
          sx={{
            background: "white",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            py: 10,
          }}
        >
          <Grid container maxWidth="md">
            <Grid item xs={12}>
              <Stack>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  color={theme.palette.background.default}
                  mb={3}
                >
                  Our completed audits
                </Typography>
                <Grid container spacing={3}>
                  {auditNodes
                    .filter((aN) => !aN.hidden && aN.status.toLowerCase() === "completed")
                    .slice(0, 5)
                    .map((node) => (
                      <Grid key={node.id} item xs={12}>
                        <Grid
                          sx={{
                            textDecoration: "none",
                            width: "100%",
                            border:
                              "2px solid " + theme.palette.background.default,
                            borderRadius: 4,
                          }}
                          component={Link}
                          to={`/audit/${node.slug.current}`}
                          container
                          p={3}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid display="flex" alignItems="center" item>
                            <Avatar
                              sx={{ height: 64, width: 64, borderRadius: 0, padding: "8px" }}
                              src={imageUrlFor(node.logo._rawAsset._ref)}
                              alt={node.name}
                            />
                            <Grid textAlign="left" ml={5}>
                              <Typography
                                variant="h5"
                                color={theme.palette.background.default}
                                fontWeight="bold"
                              >
                                {node.name}
                              </Typography>
                              <Typography
                                variant="caption"
                                textTransform="uppercase"
                                color={theme.palette.primary.main}
                              >
                                {node.network}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
                <Stack mt={3}>
                  <Button variant="contained" component={Link} to="/audits">
                    Browse all audits
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      )}

      <Container maxWidth={false}
        sx={{
          background: "white",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          py: 10,
        }}>

        <Grid container maxWidth="md" justifyContent="center">
          <Grid item xs={12}><Typography
            variant="h4"
            fontWeight="bold"
            color={theme.palette.background.default}
            mb={3}
          >
            Our partners
          </Typography></Grid>

          <Grid item xs={12} sm={4}>

            <a href="https://gempad.app" target="_blank" rel="noreferrer">
              <img style={{ width: 200 }} src="https://494231508-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F1yRkInTCJ5AemfjvUT8N%2Fuploads%2FdQMzi433EfJMaQAp3w1c%2FGemPad%20horizontal.png?alt=media&token=7e8f32f0-203b-43b3-ba47-5f87e3356488" />
            </a>

          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default IndexPage;
